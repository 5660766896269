<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.orcamentos.titulos.listagem')"
      sem-registros
      sem-filtro
      sem-botao-ok
    >
      <template v-slot:botaoSecundario>
        <div class="d-flex align-center flex-row justify-space-between w-auto">
          <botao-padrao
            class="mr-3"
          >
            {{ $t('modulos.orcamentos.visualizar_comentarios') }}
          </botao-padrao>
          <botao-padrao
            class="mx-0"
          >
            {{ $t('modulos.orcamentos.novo_comentario') }}
          </botao-padrao>
        </div>
      </template>
    </cabecalho-pagina>
    <accordion-tabela
      alterar-cor
      class="mb-10"
    >
      <template v-slot:header>
        <p> {{ $t('modulos.orcamentos.marque_os_itens_remover_orcamento') }}</p>
      </template>
      <tabela-padrao
        v-model="tabelaServicos.selecionados"
        :dados="tabelaServicos.dados"
        class="mt-2"
        :colunas="tabelaServicos.colunas"
        :por-pagina="-1"
        sem-paginacao
      >
        <template v-slot:item.acoes>
          <dropdown-padrao
            text
            color="secondary"
          >
            <template #botao>
              <v-icon> $wrenchClock </v-icon>
            </template>
          </dropdown-padrao>
        </template>
      </tabela-padrao>
    </accordion-tabela>

    <div class="d-flex align-center flex-row justify-end w-auto">
      <botao-padrao @click="associarInstrumentos">
        {{ $t('modulos.orcamentos.selecionar_instrumento') }}
      </botao-padrao>
    </div>
    <accordion-tabela
      alterar-cor
      class="mb-10"
    >
      <template v-slot:header>
        <p> {{ $t('modulos.orcamentos.adicionar_instrumento_orcamento') }}</p>
      </template>
      <tabela-padrao
        v-model="tabelaInstrumentos.selecionados"
        :dados="tabelaInstrumentos.dados"
        class="mt-2"
        :colunas="tabelaInstrumentos.colunas"
        :por-pagina="-1"
        sem-paginacao
      >
        <template v-slot:item.acoes>
          <dropdown-padrao
            text
            color="secondary"
          >
            <template #botao>
              <v-icon> $wrenchClock </v-icon>
            </template>
          </dropdown-padrao>
        </template>
      </tabela-padrao>
    </accordion-tabela>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
    <instrumento-modal
      ref="modal-instrumento"
      :instrumentos-existentes="orcamento.instrumentos"
      @associarInstrumentos="novoInstrumento"
    />
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import InstrumentoModal from './components/modais/InstrumentoModal.vue'
import OrcamentosService from '@common/services/cadastros/OrcamentosService';
import helpers from "@common/utils/helpers"
import _ from 'lodash'

export default {
  components: {
    CabecalhoPagina,
    InstrumentoModal
  },
  props: ['id'],
  data() {
    return {
      orcamento: {},
      orcamentoItems: [],
      cloneOrcamento: [],
      tabelaServicos: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'tag',
            text: this.$t('modulos.orcamentos.tabela.tag'),
            sortable: true,
          },
          {
            value: 'tipoInstrumento',
            text: this.$t('modulos.orcamentos.tabela.tipo_instrumento'),
            sortable: true,
          },
          {
            value: 'codigo',
            text: this.$t('modulos.orcamentos.tabela.codigo_servico'),
            sortable: true,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.local_estocagem.tabela.descricao'),
            sortable: false,
          },
          {
            value: 'valorTotal',
            text: this.$t('modulos.contrato.tabela.valor_total'),
            sortable: true,
            formatter: (v) => helpers.formatarMoeda(v)
          },
        ],
      },
      tabelaInstrumentos: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'tag',
            text: this.$t('modulos.orcamentos.tabela.tag'),
            sortable: true,
          },
          {
            value: 'tipoInstrumento',
            text: this.$t('modulos.orcamentos.tabela.tipo_instrumento'),
            sortable: true,
          },
        ],
      },
    };
  },
  mounted() {
    this.buscar()
  },
  methods: {
    buscar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrcamentosService.buscar(this.id)
        .then(res => {
          this.validarSituacao(res.data.status)
          this.orcamento.id = res.data.id
          this.orcamento.instrumentos = []
          this.orcamento.servicos = []
          this.orcamento.instrumentosAdicionados = []

          res.data.orcamentoItem?.forEach(item => {
            this.orcamentoItems.push({
              id: item.id,
              descricao: item.descricao,
              flagAdicionado: false,
              orcamentoItemDetalhe: [],
              orcamentoItemInstrumento: [],
            })
            item.orcamentoItemInstrumento?.forEach(itemInstrumento => {
              const instrumento = {}
              instrumento.id = itemInstrumento.instrumento.id
              instrumento.itemId = itemInstrumento.id
              instrumento.orcamentoItemId = item.id
              instrumento.tag = itemInstrumento.instrumento?.nome;
              instrumento.flagAdicionado = false;
              instrumento.tipoInstrumento = itemInstrumento.tipoInstrumento.nome

              this.orcamento.instrumentos.push(instrumento)
            })
            item.orcamentoItemDetalhe?.forEach(itemDetalhe => {
              const servico = {}
              servico.tag = itemDetalhe.servico?.nome;
              servico.valorTotal = itemDetalhe.valorTotal;
              servico.descricao = itemDetalhe.descricao;
              servico.tipoInstrumento = "TipoInstrumento";
              servico.tipoServico = itemDetalhe.servico?.tipoServico
              servico.codigo = itemDetalhe.servico?.codigo;
              servico.flagRemovidoCliente = false;
              servico.id = itemDetalhe.servico?.id
              servico.itemId = itemDetalhe.id
              servico.orcamentoItemId = item.id
              this.orcamento.servicos.push(servico);
            })
          });
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.orcamentos.erros.orcamento_invalido'));
        })
        .finally(() => {
           this.cloneOrcamento = _.cloneDeep(this.orcamentoItems)
           this.$store.dispatch('Layout/terminarCarregamento');
           this.tabelaServicos.dados = this.orcamento.servicos
           this.tabelaInstrumentos.dados = this.orcamento.instrumentos
        });
    },
    validarSituacao(situacao){
      if(situacao != "AguardandoAprovacao") this.$router.push({ name: 'orcamentos' });
    },
    confirmacaoSalvar: function () {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
    },
    atualizarTabelaInstrumentos(){
      this.tabelaInstrumentos.dados = [...this.orcamento.instrumentos].concat([...this.orcamento.instrumentosAdicionados])
    },
    novoInstrumento(instrumentos){
      this.orcamento.instrumentosAdicionados = []
      instrumentos.forEach(item => {
        const instrumento = {}
        instrumento.id = item.id
        instrumento.tag = item.codigoTag;
        instrumento.flagAdicionado = true;
        instrumento.tipoInstrumento = item.tipoInstrumento.nome
        const instrumentoJaInserido = this.orcamento.instrumentos.filter(el => el.id === item.id)
        if(instrumentoJaInserido.length == 0) this.orcamento.instrumentosAdicionados.push(instrumento);
      })
      this.atualizarTabelaInstrumentos()
    },
    montarObjetoRequest(){
      this.orcamentoItems = _.cloneDeep(this.cloneOrcamento)

      this.tabelaServicos.selecionados.forEach(servico => {
        this.orcamento.servicos.find(el => el.id == servico.id).flagRemovidoCliente = true
      })
      this.orcamento.instrumentos.forEach((instrumento) => {
        const orcamentoItem =  this.orcamentoItems.find(el => el.id == instrumento.orcamentoItemId)
        const instrumentoItem = {}
        instrumentoItem.id = instrumento.itemId
        instrumentoItem.instrumentoId = instrumento.id
        instrumentoItem.flagAdicionadoCliente = false
        orcamentoItem.orcamentoItemInstrumento.push(instrumentoItem)
      })
      this.orcamento.servicos.forEach(servico => {
        const orcamentoItem =  this.orcamentoItems.find(el => el.id == servico.orcamentoItemId)
        const servicoDetalhe = {}
        servicoDetalhe.id = servico.itemId
        servicoDetalhe.itemId = null;
        servicoDetalhe.servicoId = servico.id
        servicoDetalhe.flagRemovidoCliente = servico.flagRemovidoCliente
        servicoDetalhe.tipoServico = servico.tipoServico
        orcamentoItem.orcamentoItemDetalhe.push(servicoDetalhe)
      })
        if(this.orcamento.instrumentosAdicionados.length != 0) this.associarInstrumentosAdicionados();
    },
    associarInstrumentosAdicionados(){
      const orcamentoItemAdicionados = {
        id: null,
        descricao: 'Instrumentos adicionados pelo Cliente',
        flagAdicionadoCliente: true,
        orcamentoItemDetalhe: [],
        orcamentoItemInstrumento: [],
      }
      this.orcamento.instrumentosAdicionados.forEach(instrumento => {
        orcamentoItemAdicionados.orcamentoItemInstrumento
          .push({
            id: null,
            instrumentoId: instrumento.id,
            flagAdicionadoCliente: true
          })
      })
      this.orcamentoItems.push(orcamentoItemAdicionados)
    },
    associarInstrumentos(){
      this.$refs['modal-instrumento'].abrirModal()
    },
    salvar(){
      this.montarObjetoRequest()
      OrcamentosService.alterarOrcamentoCliente(this.orcamento.id, {orcamentoItem: this.orcamentoItems}).then(() => {
        this.toastSucesso(this.$t(`modulos.orcamentos.cadastro_sucesso`));
        this.$router.push({ name: "orcamentos" });
      })

    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'orcamentos' });
      });
    },
  },
};
</script>
